import { APP_CONFIG } from 'mangools-commons/dist/configs/app'
import queryString from 'query-string';

import HumanService from 'mangools-commons/dist/services/HumanService';
import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';

import {
    parseAudienceOverview,
    parseBacklinkProfileOverview,
    parseCompetitorsOverview,
    parseMainOverview,
    parseTopContentOverview,
    parseTrafficOverview,
} from 'sources/parsers/DataParser';

// Constants
const AUDIENCE_OVERVIEW_ENDPOINT = '/audience';
const BACKLINK_PROFILE_OVERVIEW_ENDPOINT = '/backlink-profile';
const COMPETITORS_OVERVIEW_ENDPOINT = '/competitors';
const MAIN_OVERVIEW_ENDPOINT = '/overview';
const TOP_CONTENT_OVERVIEW_ENDPOINT = '/top-content';
const TRAFFIC_OVERVIEW_ENDPOINT = '/traffic';

/**
 * Data source class handling retrieval of url metrics data from query.
 */
class DataSource {
    static getData({ parser, endpoint }, { accessToken }, { url }) {
        const requestQuery = queryString.stringify({ url });
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.API_PATH}${endpoint}?${requestQuery}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: parser(data, url),
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }

    static getMainOverviewData({ accessToken }, { url }) {
        return DataSource.getData(
            {
                parser: parseMainOverview,
                endpoint: MAIN_OVERVIEW_ENDPOINT,
            },
            {
                accessToken,
            },
            {
                url,
            },
        );
    }

    static getTrafficOverviewData({ accessToken }, { url }) {
        return DataSource.getData(
            {
                parser: parseTrafficOverview,
                endpoint: TRAFFIC_OVERVIEW_ENDPOINT,
            },
            {
                accessToken,
            },
            {
                url,
            },
        );
    }

    static getCompetitorsOverviewData({ accessToken }, { url }) {
        return DataSource.getData(
            {
                parser: parseCompetitorsOverview,
                endpoint: COMPETITORS_OVERVIEW_ENDPOINT,
            },
            {
                accessToken,
            },
            {
                url,
            },
        );
    }

    static getTopContentOverviewData({ accessToken }, { url }) {
        return DataSource.getData(
            {
                parser: parseTopContentOverview,
                endpoint: TOP_CONTENT_OVERVIEW_ENDPOINT,
            },
            {
                accessToken,
            },
            {
                url,
            },
        );
    }

    static getBacklinkProfileOverviewData({ accessToken }, { url }) {
        return DataSource.getData(
            {
                parser: parseBacklinkProfileOverview,
                endpoint: BACKLINK_PROFILE_OVERVIEW_ENDPOINT,
            },
            {
                accessToken,
            },
            {
                url,
            },
        );
    }

    static getAudienceOverviewData({ accessToken }, { url }) {
        return DataSource.getData(
            {
                parser: parseAudienceOverview,
                endpoint: AUDIENCE_OVERVIEW_ENDPOINT,
            },
            {
                accessToken,
            },
            {
                url,
            },
        );
    }
}

export default DataSource;

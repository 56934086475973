import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoonAuto } from 'mangools-commons/dist/constants/Icons';

import {
    faBars,
    faArrowRight,
    faArrowUp,
    faSearch,
    faQuestionCircle,
    faCaretUp,
    faCaretDown,
    faInfoCircle,
    faEllipsisV,
    faChevronUp,
    faChevronDown,
    faDownload,
    faTrophy,
    faImage,
    faLink,
    faShareAlt,
    faGlobe,
    faVenus,
    faGraduationCap,
    faMapMarker,
    faMars,
    faSort,
    faSortDown,
    faSortUp,
    faNewspaper,
    faClipboard,
    faFileExcel,
    faTimes,
    faPowerOff,
    faHistory,
    faUndo,
    faPlug,
    faBell,
    faExternalLinkAlt,
    faSignOutAlt,
    faClock,
    faEye,
    faCreditCard,
    faUser,
    faSun,
    faMoon,
    faStar,
    faLock,
    faUnlock,
} from '@fortawesome/free-solid-svg-icons';

import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

library.add(
    faBars,
    faArrowRight,
    faArrowUp,
    faSearch,
    faQuestionCircle,
    faCaretUp,
    faCaretDown,
    faInfoCircle,
    faEllipsisV,
    faChevronUp,
    faChevronDown,
    faDownload,
    faTrophy,
    faImage,
    faLink,
    faShareAlt,
    faGlobe,
    faVenus,
    faGraduationCap,
    faMapMarker,
    faMars,
    faSort,
    faSortDown,
    faSortUp,
    faNewspaper,
    faClipboard,
    faFileExcel,
    faTimes,
    faPowerOff,
    faTrashAlt,
    faHistory,
    faUndo,
    faPlug,
    faBell,
    faExternalLinkAlt,
    faSignOutAlt,
    faClock,
    faEye,
    faCreditCard,
    faUser,
    faSun,
    faMoon,
    faMoonAuto,
    faStar,
    faLock,
    faUnlock,
);

import { createSelector } from 'reselect';

// Filter
export const filterVisibilitySelector = state => state.ui.filter.visibility;

// Messages
export const accessDeniedMessageVisibilitySelector = state => state.ui.messages.accessDenied.visibility;
export const failureMessageDetailsSelector = state => state.ui.messages.failure.details;
export const failureMessageHeaderSelector = state => state.ui.messages.failure.header;
export const failureMessageVisibilitySelector = state => state.ui.messages.failure.visibility;
export const loggedOutMessageVisibilitySelector = state => state.ui.messages.loggedOut.visibility;
export const needToSignInMessageVisibilitySelector = state => state.ui.messages.needToSignIn.visibility;
export const noConnectionMessageVisibilitySelector = state => state.ui.messages.noConnection.visibility;
export const urlTypeInfoMessageVisibilitySelector = state => state.ui.messages.urlTypeInfo.visibility;

// Dropdowns
export const exportDropdownVisibilitySelector = state => state.ui.dropdowns.export.visibility;
export const helpDropdownVisibilitySelector = state => state.ui.dropdowns.help.visibility;
export const userDropdownVisibilitySelector = state => state.ui.dropdowns.user.visibility;

// Returns true if any of the dropdowns is visible
export const anyDropdownVisibleSelector = createSelector(
    [exportDropdownVisibilitySelector, helpDropdownVisibilitySelector],
    (exportDropdownVisible, helpDropdownVisible) => exportDropdownVisible || helpDropdownVisible,
);

// Panels
export const appPanelVisibilitySelector = state => state.ui.panels.app.visibility;
export const historyPanelVisibilitySelector = state => state.ui.panels.history.visibility;

// Misc
export const onlineStatusSelector = state => state.ui.misc.onlineStatus;
export const newVersionNotificationShownSelector = state => state.ui.misc.newVersionNotificationShown;
export const navigatedInternallySelector = state => state.ui.misc.navigatedInternally;
export const colorSchemeSelector = state => state.ui.misc.colorScheme;

export const overlayVisibilitySelector = createSelector(
    [appPanelVisibilitySelector, historyPanelVisibilitySelector, failureMessageVisibilitySelector],
    (appVisibility, historyVisibility, failure) => !failure && (appVisibility || historyVisibility),
);

export const mobileOverlayVisibilitySelector = createSelector(
    [anyDropdownVisibleSelector],
    anyDropdownVisible => anyDropdownVisible,
);

// Dashboard
export const dashboardDominantColorRgbSelector = state => state.ui.dashboard.dominantColorRgb;

export const currentColorSchemeSelector = createSelector(
    [colorSchemeSelector],
    (currentColorScheme) => {
        return currentColorScheme;
    },
);

import { APP_CONFIG } from 'mangools-commons/dist/configs/app'

import ErrorCodes from 'mangools-commons/dist/constants/ErrorCodes';
import HumanService from 'mangools-commons/dist/services/HumanService';

// Constants
const ENDPOINT = '/keywords.csv';

/**
 * Data source class handling communication with API for keywords data.
 */
class KeywordSource {
    static exportData(accessToken, selectedKeywordIds, includeMetrics) {
        const requestURL = `${APP_CONFIG.API_HOST}${APP_CONFIG.KWFINDER_API_PATH}${ENDPOINT}`;

        // Generate body data
        const requestBody = {};
        requestBody.keyword_ids = selectedKeywordIds;

        if (!includeMetrics) {
            // Only include `kw` field
            requestBody.fields = 'kw';
        }

        return fetch(requestURL, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'text/csv',
                'Content-Type': 'application/json',
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
            body: JSON.stringify(requestBody),
        })
            .then(response => {
                if (response.ok) {
                    return response.text().then(text => ({
                        error: false,
                        payload: text,
                    }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default KeywordSource;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import keymaster from 'keymaster';
import classnames from 'classnames';

import Overlay from 'components/layout/Overlay';
import MessageContainer from 'components/messages/MessageContainer';
import ModalContainer from 'components/modals/ModalContainer';
import NavbarContainer from 'components/layout/navbar/NavbarContainer';
import PanelContainer from 'components/panels/PanelContainer';
import TopLevelMessageContainer from 'components/messages/topLevel/TopLevelMessageContainer';

import { closeAll } from 'actions/uiActions';

import {
    mobileOverlayVisibilitySelector,
    onlineStatusSelector,
    overlayVisibilitySelector,
} from 'selectors/uiSelectors';

import { newUserMessageVisibilitySelector } from 'selectors/userSelectors';
import { announcementMesageVisibilitySelector } from 'selectors/dataSelectors';
import { Alert } from 'mangools-react-components/src';

const LayoutContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    const mobileOverlayVisibility = useSelector(mobileOverlayVisibilitySelector);
    const onlineStatus = useSelector(onlineStatusSelector);
    const overlayVisibility = useSelector(overlayVisibilitySelector);
    const topLevelMessageVisible = useSelector(
        (state) =>
            newUserMessageVisibilitySelector(state) || announcementMesageVisibilitySelector(state),
    );

    useEffect(() => {
        keymaster('esc', () => dispatch(closeAll()));

        return () => {
            keymaster.unbind('esc');
        };
    }, [dispatch]);

    const appClasses = classnames({
        'is-offline': onlineStatus === false,
        'siteprofiler-app': true,
        'is-messaged': topLevelMessageVisible,
    });

    return (
        <div className={appClasses}>
            <TopLevelMessageContainer/>
            <NavbarContainer currentRoute={location.pathname}/>
            <Outlet/>
            <ModalContainer currentRoute={location.pathname}/>
            <MessageContainer/>
            <PanelContainer/>
            <Overlay mobile={false} onClick={() => dispatch(closeAll())} visibility={overlayVisibility}/>
            <Overlay mobile onClick={() => dispatch(closeAll())} visibility={mobileOverlayVisibility}/>
            <div className="uk-hidden-small">
                <Alert/>
            </div>
        </div>
    );
};

export default LayoutContainer;

import React from 'react';
import { eventChannel } from 'redux-saga';
import { delay, call, spawn, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import isNil from 'ramda/src/isNil';

import DownloaderService from 'mangools-commons/dist/services/DownloaderService';
import FileService from 'mangools-commons/dist/services/FileService';

import { APP_CONFIG } from 'mangools-commons/dist/configs/app'

import ExportService from 'services/ExportService';
import AnnouncementsSource from 'sources/AnnouncementsSource';
import DataSource from 'sources/DataSource';
import HistorySource from 'sources/HistorySource';
import KeywordSource from 'sources/KeywordSource';
import VersionSource from 'sources/VersionSource';
import UnleashSource from 'sources/UnleashSource';

import {
    errorAnnouncementsAction,
    // errorAudienceOverviewDataAction,
    errorBacklinkProfileOverviewDataAction,
    errorCompetitorsOverviewDataAction,
    errorHistoryAction,
    errorMainOverviewDataAction,
    errorTopContentOverviewDataAction,
    // errorTrafficOverviewDataAction,
    fetchingAnnouncementsAction,
    // fetchingAudienceOverviewDataAction,
    fetchingBacklinkProfileOverviewDataAction,
    fetchingCompetitorsOverviewDataAction,
    fetchingHistoryAction,
    fetchingMainOverviewDataAction,
    fetchingTopContentOverviewDataAction,
    // fetchingTrafficOverviewDataAction,
    receivedAnnouncementsAction,
    // receivedAudienceOverviewDataAction,
    receivedBacklinkProfileOverviewDataAction,
    receivedCompetitorsOverviewDataAction,
    receivedHistoryAction,
    receivedMainOverviewDataAction,
    receivedTopContentOverviewDataAction,
    // receivedTrafficOverviewDataAction,
    skippedHistoryAction,
    receivedDeleteHistoryAction,
    errorDeleteHistoryAction,
} from 'actions/dataActions';

import {
    setNewVersionNotificationShown,
    showAccessDeniedMessage,
    showFailureMessage,
    showNoConnectionMessage,
} from 'actions/uiActions';

import { requestedLimitsAction, setUnleashSessionAction } from 'actions/userActions';

import { accessTokenSelector } from 'selectors/userSelectors';

import { urlParamSelector } from 'selectors/paramsSelectors';

import {
    backlinkProfileOverviewDataFetchedSelector,
    backlinkProfileOverviewDataSelector,
    competitorsOverviewDataFetchedSelector,
    mainOverviewDataFetchedSelector,
    topContentOverviewDataFetchedSelector,
    trafficOverviewDataSelector,
} from 'selectors/dataSelectors';

import { newVersionNotificationShownSelector } from 'selectors/uiSelectors';

import { showInfoNotification } from 'sagas/uiSagas';

import { handleUncaught, logError } from 'sagas/errorSagas';
import Defaults from 'mangools-commons/dist/constants/Defaults';

import ErrorCodes, {
    INTERNAL_TIMEOUT_ERROR_PAYLOAD,
    INTERNAL_UNCAUGHT_ERROR_PAYLOAD,
} from 'mangools-commons/dist/constants/ErrorCodes';

import ActionTypes from 'constants/ActionTypes';
import Strings from 'constants/Strings';
import ExportTypes from 'constants/ExportTypes';
import { ErrorTypes } from 'constants/ErrorTypes';

const EXPORT_PREFIX = 'siteprofiler_';
const EXPORT_SUFFIX = '_export';

const fetchMainOverviewData = handleUncaught(
    function* fetchMainOverviewData(action, retrying = false) {
        yield put(fetchingMainOverviewDataAction());
        const accessToken = yield select(accessTokenSelector);
        const url = yield select(urlParamSelector);

        const { result, _timeout } = yield race({
            result: call(DataSource.getMainOverviewData, { accessToken }, { url }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedMainOverviewDataAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorMainOverviewDataAction(payload));
                            yield put(showNoConnectionMessage());
                            yield call(logError, 'FetchMainOverviewDataSaga', payload);
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchMainOverviewData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorMainOverviewDataAction(payload));
                        yield put(showAccessDeniedMessage());
                        yield call(logError, 'FetchMainOverviewDataSaga', payload);
                        break;
                    }
                    case ErrorCodes.UNPROCESSABLE_ENTITY: {
                        yield put(errorMainOverviewDataAction(payload));
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorMainOverviewDataAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorMainOverviewDataAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            // yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorMainOverviewDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchMainOverviewDataSaga', payload);
                        } else {
                            yield call(fetchMainOverviewData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorMainOverviewDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
                            yield call(logError, 'FetchMainOverviewDataSaga', payload);
                        } else {
                            yield call(fetchMainOverviewData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorMainOverviewDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_data_error }));
            yield call(logError, 'FetchMainOverviewDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorMainOverviewDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
    },
);

// const fetchTrafficOverviewData = handleUncaught(function* fetchTrafficOverviewData(action, retrying = false) {
//     yield put(fetchingTrafficOverviewDataAction());
//     const mainDataFetched = yield select(mainOverviewDataFetchedSelector);

//     if (mainDataFetched === false) {
//         // Wait for main overview data to be fetched or error
//         const { _received, _error } = yield race({
//             _received: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_RECEIVED),
//             _error: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_ERROR),
//         });
//     }

//     const accessToken = yield select(accessTokenSelector);
//     const url = yield select(urlParamSelector);

//     const { result, _timeout } = yield race({
//         result: call(DataSource.getTrafficOverviewData, { accessToken }, { url }),
//         _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT)
//     });

//     if (!isNil(result)) {
//         const { error, payload } = result;

//         if (!error) {
//             yield put(receivedTrafficOverviewDataAction(payload));
//         } else {
//             switch (payload.status) {
//                 case ErrorCodes.FETCH_ERROR: {
//                     if (retrying === true) {
//                         yield put(errorTrafficOverviewDataAction(payload));
//                         yield put(showNoConnectionMessage());
//                         yield call(logError, 'FetchTrafficOverviewDataSaga', payload);
//                     } else {
//                         // Wait for CONNECTION_RETRY_DELAY and try again
//                         yield delay(Defaults.CONNECTION_RETRY_DELAY);
//                         yield call(fetchTrafficOverviewData, action, true);
//                     }
//                     break;
//                 }
//                 case ErrorCodes.ACCESS_DENIED: {
//                     yield put(errorTrafficOverviewDataAction(payload));
//                     yield put(showAccessDeniedMessage());
//                     yield call(logError, 'FetchTrafficOverviewDataSaga', payload);
//                     break;
//                 }
//                 case ErrorCodes.TOO_MANY_REQUESTS: {
//                     yield put(errorTrafficOverviewDataAction(payload));

//                     if (payload.type === ErrorTypes.REPEAT_REQUEST) {
//                         yield put(showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }));
//                     } else if (payload.type === ErrorTypes.RATE_LIMIT) {
//                         yield put(requestedLimitsAction());
//                         // yield put(showPricingMessage());
//                     }

//                     break;
//                 }
//                 case ErrorCodes.SERVICE_UNAVAILABLE: {
//                     if (retrying === true) {
//                         yield put(errorTrafficOverviewDataAction(payload));
//                         yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
//                         yield call(logError, 'FetchTrafficOverviewDataSaga', payload);
//                     } else {
//                         yield call(fetchTrafficOverviewData, action, true);
//                     }
//                     break;
//                 }
//                 case ErrorCodes.INTERNAL_SERVER_ERROR:
//                 default: {
//                     if (retrying === true) {
//                         yield put(errorTrafficOverviewDataAction(payload));
//                         yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
//                         yield call(logError, 'FetchTrafficOverviewDataSaga', payload);
//                     } else {
//                         yield call(fetchTrafficOverviewData, action, true);
//                     }
//                     break;
//                 }
//             }
//         }
//     } else {
//         // Timeout
//         yield put(errorTrafficOverviewDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
//         yield put(showFailureMessage({ details: Strings.messages.failure.fetch_data_error }));
//         yield call(logError, 'FetchTrafficOverviewDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
//     }
// }, function* onError() {
//     yield put(errorTrafficOverviewDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
//     yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
// });

const fetchCompetitorsOverviewData = handleUncaught(
    function* fetchCompetitorsOverviewData(action, retrying = false) {
        yield put(fetchingCompetitorsOverviewDataAction());
        const mainDataFetched = yield select(mainOverviewDataFetchedSelector);

        if (mainDataFetched === false) {
            // Wait for main overview data to be fetched or error
            const { _received, _error } = yield race({
                _received: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_RECEIVED),
                _error: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_ERROR),
            });
        }

        const accessToken = yield select(accessTokenSelector);
        const url = yield select(urlParamSelector);

        const { result, _timeout } = yield race({
            result: call(DataSource.getCompetitorsOverviewData, { accessToken }, { url }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedCompetitorsOverviewDataAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorCompetitorsOverviewDataAction(payload));
                            yield put(showNoConnectionMessage());
                            yield call(logError, 'FetchCompetitorsOverviewDataSaga', payload);
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchCompetitorsOverviewData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorCompetitorsOverviewDataAction(payload));
                        yield put(showAccessDeniedMessage());
                        yield call(logError, 'FetchCompetitorsOverviewDataSaga', payload);
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorCompetitorsOverviewDataAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorCompetitorsOverviewDataAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            // yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorCompetitorsOverviewDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchCompetitorsOverviewDataSaga', payload);
                        } else {
                            yield call(fetchCompetitorsOverviewData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorCompetitorsOverviewDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
                            yield call(logError, 'FetchCompetitorsOverviewDataSaga', payload);
                        } else {
                            yield call(fetchCompetitorsOverviewData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorCompetitorsOverviewDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_data_error }));
            yield call(logError, 'FetchCompetitorsOverviewDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorCompetitorsOverviewDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
    },
);

const fetchTopContentOverviewData = handleUncaught(
    function* fetchTopContentOverviewData(action, retrying = false) {
        yield put(fetchingTopContentOverviewDataAction());
        const mainDataFetched = yield select(mainOverviewDataFetchedSelector);

        if (mainDataFetched === false) {
            // Wait for main overview data to be fetched or error
            const { _received, _error } = yield race({
                _received: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_RECEIVED),
                _error: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_ERROR),
            });
        }

        const accessToken = yield select(accessTokenSelector);
        const url = yield select(urlParamSelector);

        const { result, _timeout } = yield race({
            result: call(DataSource.getTopContentOverviewData, { accessToken }, { url }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedTopContentOverviewDataAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorTopContentOverviewDataAction(payload));
                            yield put(showNoConnectionMessage());
                            yield call(logError, 'FetchTopContentOverviewDataSaga', payload);
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchTopContentOverviewData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorTopContentOverviewDataAction(payload));
                        yield put(showAccessDeniedMessage());
                        yield call(logError, 'FetchTopContentOverviewDataSaga', payload);
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorTopContentOverviewDataAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorTopContentOverviewDataAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            // yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorTopContentOverviewDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchTopContentOverviewDataSaga', payload);
                        } else {
                            yield call(fetchTopContentOverviewData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorTopContentOverviewDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
                            yield call(logError, 'FetchTopContentOverviewDataSaga', payload);
                        } else {
                            yield call(fetchTopContentOverviewData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorTopContentOverviewDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_data_error }));
            yield call(logError, 'FetchTopContentOverviewDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorTopContentOverviewDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
    },
);

const fetchBacklinkProfileOverviewData = handleUncaught(
    function* fetchBacklinkProfileOverviewData(action, retrying = false) {
        yield put(fetchingBacklinkProfileOverviewDataAction());
        const mainDataFetched = yield select(mainOverviewDataFetchedSelector);

        if (mainDataFetched === false) {
            // Wait for main overview data to be fetched or error
            const { _received, _error } = yield race({
                _received: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_RECEIVED),
                _error: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_ERROR),
            });
        }

        const accessToken = yield select(accessTokenSelector);
        const url = yield select(urlParamSelector);

        const { result, _timeout } = yield race({
            result: call(DataSource.getBacklinkProfileOverviewData, { accessToken }, { url }),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedBacklinkProfileOverviewDataAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorBacklinkProfileOverviewDataAction(payload));
                            yield put(showNoConnectionMessage());
                            yield call(logError, 'FetchBacklinkProfileOverviewDataSaga', payload);
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchBacklinkProfileOverviewData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorBacklinkProfileOverviewDataAction(payload));
                        yield put(showAccessDeniedMessage());
                        yield call(logError, 'FetchBacklinkProfileOverviewDataSaga', payload);
                        break;
                    }
                    case ErrorCodes.UNATHORIZED: {
                        yield put(errorBacklinkProfileOverviewDataAction(payload));
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorBacklinkProfileOverviewDataAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }),
                            );
                        } else if (payload.type === ErrorTypes.RATE_LIMIT) {
                            yield put(requestedLimitsAction());
                            // yield put(showPricingMessage());
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorBacklinkProfileOverviewDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchBacklinkProfileOverviewDataSaga', payload);
                        } else {
                            yield call(fetchBacklinkProfileOverviewData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorBacklinkProfileOverviewDataAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
                            yield call(logError, 'FetchBacklinkProfileOverviewDataSaga', payload);
                        } else {
                            yield call(fetchBacklinkProfileOverviewData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            // Timeout
            yield put(errorBacklinkProfileOverviewDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield put(showFailureMessage({ details: Strings.messages.failure.fetch_data_error }));
            yield call(logError, 'FetchBacklinkProfileOverviewDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorBacklinkProfileOverviewDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
    },
);

// const fetchAudienceOverviewData = handleUncaught(function* fetchAudienceOverviewData(
//     action,
//     retrying = false
// ) {
//     yield put(fetchingAudienceOverviewDataAction());
//     const mainDataFetched = yield select(mainOverviewDataFetchedSelector);

//     if (mainDataFetched === false) {
//         // Wait for main overview data to be fetched or error
//         const { _received, _error } = yield race({
//             _received: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_RECEIVED),
//             _error: take(ActionTypes.DATA_MAIN_OVERVIEW_DATA_ERROR),
//         });
//     }

//     const accessToken = yield select(accessTokenSelector);
//     const url = yield select(urlParamSelector);

//     const { result, _timeout } = yield race({
//         result: call(DataSource.getAudienceOverviewData, { accessToken }, { url }),
//         _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT)
//     });

//     if (!isNil(result)) {
//         const { error, payload } = result;

//         if (!error) {
//             yield put(receivedAudienceOverviewDataAction(payload));
//         } else {
//             switch (payload.status) {
//                 case ErrorCodes.FETCH_ERROR: {
//                     if (retrying === true) {
//                         yield put(errorAudienceOverviewDataAction(payload));
//                         yield put(showNoConnectionMessage());
//                         yield call(logError, 'FetchAudienceOverviewDataSaga', payload);
//                     } else {
//                         // Wait for CONNECTION_RETRY_DELAY and try again
//                         yield delay(Defaults.CONNECTION_RETRY_DELAY);
//                         yield call(fetchAudienceOverviewData, action, true);
//                     }
//                     break;
//                 }
//                 case ErrorCodes.ACCESS_DENIED: {
//                     yield put(errorAudienceOverviewDataAction(payload));
//                     yield put(showAccessDeniedMessage());
//                     yield call(logError, 'FetchAudienceOverviewDataSaga', payload);
//                     break;
//                 }
//                 case ErrorCodes.UNATHORIZED: {
//                     yield put(errorAudienceOverviewDataAction(payload));
//                     break;
//                 }
//                 case ErrorCodes.TOO_MANY_REQUESTS: {
//                     yield put(errorAudienceOverviewDataAction(payload));

//                     if (payload.type === ErrorTypes.REPEAT_REQUEST) {
//                         yield put(showFailureMessage({ details: Strings.messages.failure.too_many_requests_error }));
//                     } else if (payload.type === ErrorTypes.RATE_LIMIT) {
//                         yield put(requestedLimitsAction());
//                         // yield put(showPricingMessage());
//                     }

//                     break;
//                 }
//                 case ErrorCodes.SERVICE_UNAVAILABLE: {
//                     if (retrying === true) {
//                         yield put(errorAudienceOverviewDataAction(payload));
//                         yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
//                         yield call(logError, 'FetchAudienceOverviewDataSaga', payload);
//                     } else {
//                         yield call(fetchAudienceOverviewData, action, true);
//                     }
//                     break;
//                 }
//                 case ErrorCodes.INTERNAL_SERVER_ERROR:
//                 default: {
//                     if (retrying === true) {
//                         yield put(errorAudienceOverviewDataAction(payload));
//                         yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
//                         yield call(logError, 'FetchAudienceOverviewDataSaga', payload);
//                     } else {
//                         yield call(fetchAudienceOverviewData, action, true);
//                     }
//                     break;
//                 }
//             }
//         }
//     } else {
//         // Timeout
//         yield put(errorAudienceOverviewDataAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
//         yield put(showFailureMessage({ details: Strings.messages.failure.fetch_data_error }));
//         yield call(logError, 'FetchAudienceOverviewDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
//     }
// }, function* onError() {
//     yield put(errorAudienceOverviewDataAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
//     yield put(showFailureMessage({ details: Strings.messages.failure.fetch_link_data_error }));
// });

const fetchHistoryData = handleUncaught(
    function* fetchHistoryData(action, retrying = false) {
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            yield put(fetchingHistoryAction());

            const { result, _timeout } = yield race({
                result: call(HistorySource.getData, accessToken),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;

                if (!error) {
                    yield put(receivedHistoryAction(payload));
                } else {
                    switch (payload.status) {
                        case ErrorCodes.FETCH_ERROR: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(showNoConnectionMessage());
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                // Wait for CONNECTION_RETRY_DELAY and try again
                                yield delay(Defaults.CONNECTION_RETRY_DELAY);
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.ACCESS_DENIED: {
                            yield put(errorHistoryAction(payload));
                            yield put(showAccessDeniedMessage());
                            yield call(logError, 'FetchHistoryDataSaga', payload);
                            break;
                        }
                        case ErrorCodes.TOO_MANY_REQUESTS: {
                            yield put(errorHistoryAction(payload));

                            if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                                yield put(
                                    showFailureMessage({
                                        details: Strings.messages.failure.too_many_requests_error,
                                    }),
                                );
                            }

                            break;
                        }
                        case ErrorCodes.SERVICE_UNAVAILABLE: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                        case ErrorCodes.INTERNAL_SERVER_ERROR:
                        default: {
                            if (retrying === true) {
                                yield put(errorHistoryAction(payload));
                                yield put(
                                    showFailureMessage({ details: Strings.messages.failure.fetch_history_error }),
                                );
                                yield call(logError, 'FetchHistoryDataSaga', payload);
                            } else {
                                yield call(fetchHistoryData, action, true);
                            }
                            break;
                        }
                    }
                }
            } else {
                yield put(errorHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
                yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
                yield call(logError, 'FetchHistoryDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            }
        } else {
            yield put(skippedHistoryAction());
        }
    },
    function* onError() {
        yield put(errorHistoryAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
        yield put(showFailureMessage({ details: Strings.messages.failure.fetch_history_error }));
    },
);

const deleteHistoryData = handleUncaught(function* deleteHistoryData(action, retrying = false) {
    const accessToken = yield select(accessTokenSelector);

    const { result, _timeout } = yield race({
        result: call(HistorySource.delete, { accessToken }),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(accessToken)) {
        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedDeleteHistoryAction());

                yield call(showInfoNotification, 'History was successfully cleared.');
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showNoConnectionMessage());
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorDeleteHistoryAction(payload));
                        yield put(showAccessDeniedMessage());
                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'DeleteHistorySaga', payload);
                        } else {
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorDeleteHistoryAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorDeleteHistoryAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.delete_history }));
                            yield call(logError, 'DeleteHistorySaga', payload);
                        } else {
                            yield call(deleteHistoryData, action, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorDeleteHistoryAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'DeleteHistorySaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    }
});

const fetchAnnouncements = handleUncaught(
    function* fetchAnnouncements(retrying = false) {
        yield put(fetchingAnnouncementsAction());

        const { result, _timeout } = yield race({
            result: call(AnnouncementsSource.getData),
            _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
        });

        if (!isNil(result)) {
            const { error, payload } = result;

            if (!error) {
                yield put(receivedAnnouncementsAction(payload));
            } else {
                switch (payload.status) {
                    case ErrorCodes.FETCH_ERROR: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            // yield put(showNoConnectionMessage());
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            // Wait for CONNECTION_RETRY_DELAY and try again
                            yield delay(Defaults.CONNECTION_RETRY_DELAY);
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.ACCESS_DENIED: {
                        yield put(errorAnnouncementsAction(payload));
                        yield put(showAccessDeniedMessage());
                        yield call(logError, 'FetchAnnouncementsSaga', payload);
                        break;
                    }
                    case ErrorCodes.TOO_MANY_REQUESTS: {
                        yield put(errorAnnouncementsAction(payload));

                        if (payload.type === ErrorTypes.REPEAT_REQUEST) {
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.too_many_requests_error,
                                }),
                            );
                        }

                        break;
                    }
                    case ErrorCodes.SERVICE_UNAVAILABLE: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(showFailureMessage({ details: Strings.messages.failure.maintenance }));
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                    case ErrorCodes.INTERNAL_SERVER_ERROR:
                    default: {
                        if (retrying === true) {
                            yield put(errorAnnouncementsAction(payload));
                            yield put(
                                showFailureMessage({
                                    details: Strings.messages.failure.fetch_announcements_error,
                                }),
                            );
                            yield call(logError, 'FetchAnnouncementsSaga', payload);
                        } else {
                            yield call(fetchAnnouncements, true);
                        }
                        break;
                    }
                }
            }
        } else {
            yield put(errorAnnouncementsAction(INTERNAL_TIMEOUT_ERROR_PAYLOAD));
            yield call(logError, 'FetchAnnouncementsSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
        }
    },
    function* onError() {
        yield put(errorAnnouncementsAction(INTERNAL_UNCAUGHT_ERROR_PAYLOAD));
    },
);

const exportTrafficKeywords = handleUncaught(
    function* exportTrafficKeywords(action, retrying = false) {
        const { includeMetrics, organic } = action.payload;
        const accessToken = yield select(accessTokenSelector);

        if (!isNil(accessToken)) {
            const trafficData = yield select(trafficOverviewDataSelector);
            const keywords = organic ? trafficData.keywords.organic : trafficData.keywords.paid;
            const keywordIds = keywords.map(kw => kw.id);

            const { result, _timeout } = yield race({
                result: call(KeywordSource.exportData, accessToken, keywordIds, includeMetrics),
                _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
            });

            if (!isNil(result)) {
                const { error, payload } = result;
                const url = yield select(urlParamSelector);

                /* eslint-disable max-len */
                const filename = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(
                    url,
                )}_traffic_keywords${EXPORT_SUFFIX}`;
                /* eslint-enable max-len */

                if (!error) {
                    const success = yield call(DownloaderService.downloadCSV, filename, payload);

                    if (!success) {
                        yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
                        yield call(logError, 'ExportTrafficKeywordsDataSaga|DownloaderService', payload);
                    } else {
                        yield call(showInfoNotification, 'Keywords were successfully exported.');
                    }
                } else {
                    yield put(showFailureMessage({ details: Strings.messages.failure.export_error }));
                    yield call(logError, 'ExportTrafficKeywordsDataSaga', payload);
                }
            } else if (retrying === true) {
                yield put(showFailureMessage({ details: Strings.messages.failure.export_error }));
                yield call(logError, 'ExportTrafficKeywordsDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
            } else {
                // Retry request
                yield call(exportTrafficKeywords, action, true);
            }
        }
    },
    function* onError() {
        yield put(showFailureMessage({ details: Strings.messages.failure.export_error }));
    },
);

const exportData = handleUncaught(
    function* exportData(action) {
        const { includeMetrics, type } = action.payload;
        const backlinkProfileData = yield select(backlinkProfileOverviewDataSelector);
        const url = yield select(urlParamSelector);

        let data = [];
        let fileName = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(url)}${EXPORT_SUFFIX}`;

        switch (type) {
            case ExportTypes.TOP_ANCHORS: {
                data = backlinkProfileData.topAnchors;
                fileName = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(url)}_top_anchors${EXPORT_SUFFIX}`;
                break;
            }
            case ExportTypes.TOP_REF_DOMAINS: {
                data = backlinkProfileData.topRefDomains;
                fileName = `${EXPORT_PREFIX}${FileService.sanitizeStringForFilename(
                    url,
                )}_top_ref_domains${EXPORT_SUFFIX}`;
                break;
            }
            default: {
                break;
            }
        }

        const csv = yield call(ExportService.export, {
            data,
            includeMetrics,
            type,
        });

        const success = yield call(DownloaderService.downloadCSV, fileName, csv);

        if (success) {
            yield call(showInfoNotification, 'Data were successfully exported.');
        } else {
            yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
            yield call(logError, 'ExportData|DownloaderService', {});
        }
    },
    function* onError() {
        yield put(showFailureMessage({ details: Strings.messages.failure.download_error }));
    },
);

const fetchAllDataAndPrint = handleUncaught(function* fetchAllDataAndPrint(action) {
    const backlinkFetched = yield select(backlinkProfileOverviewDataFetchedSelector);
    const topContentFetched = yield select(topContentOverviewDataFetchedSelector);
    const competitorsFetched = yield select(competitorsOverviewDataFetchedSelector);

    if (backlinkFetched && topContentFetched && competitorsFetched) {
        window.print();
    } else {
        yield call(fetchBacklinkProfileOverviewData, action, true);
        yield call(fetchTopContentOverviewData, action, true);
        yield call(fetchCompetitorsOverviewData, action, true);
        yield call(fetchHistoryData, action, true);
        yield call(fetchAnnouncements, action, true);

        window.scroll(0, 1000);
        yield delay(400);
        window.scroll(0, 2000);
        yield delay(400);
        window.scroll(0, 3000);
        yield delay(400);
        window.scroll(0, 4000);
        yield delay(400);
        window.scroll(0, 0);
        window.print();
    }
});

const checkNewAppVersion = handleUncaught(function* checkNewAppVersion(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(VersionSource.get),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            let newAppVersion;
            const currentAppVersion = APP_CONFIG.APP_VERSION;

            if (APP_CONFIG.production()) {
                newAppVersion = payload.siteprofiler;
            } else {
                newAppVersion = payload.siteprofilerBeta;
            }

            if (!isNil(newAppVersion) && !isNil(currentAppVersion)) {
                const newAppVersionParts = newAppVersion.split('.');
                const newAppVersionMajor = parseInt(newAppVersionParts[0], 10);
                const newAppVersionMinor = parseInt(newAppVersionParts[1], 10);

                const currentAppVersionParts = currentAppVersion.split('.');
                const currentAppVersionMajor = parseInt(currentAppVersionParts[0], 10);
                const currentAppVersionMinor = parseInt(currentAppVersionParts[1], 10);

                if (newAppVersionMajor > currentAppVersionMajor || newAppVersionMinor > currentAppVersionMinor) {
                    const notificationShown = yield select(newVersionNotificationShownSelector);

                    // Only show if not already shown during this session
                    if (notificationShown === false) {
                        // We have an older version of application.
                        // Show a special notification.
                        yield call(
                            showInfoNotification,
                            <div>
                                <h4 class="font-14">
                                    UPDATE AVAILABLE 🤩
                                </h4>

                                <p>
                                    Please, reload the application to get newest features and prevent possible glitches.
                                </p>

                                <br />

                                <button
                                    class="mg-btn is-xsmall is-orange is-gradient mg-margin-t-5"
                                    onclick="location.reload();"
                                    type="button"
                                >
                                    Reload now
                                </button>
                            </div>);

                        yield put(setNewVersionNotificationShown());
                    }
                }
            }
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchAppVersionDataSaga', payload);
                    } else {
                        yield call(checkNewAppVersion, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchAppVersionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

function newAppVersionCheckIntervalChannel() {
    return eventChannel(emitter => {
        const intervalId = setInterval(() => {
            emitter({
                intervalId,
            });
        }, Defaults.APP_VERSION_CHECK_INTERVAL);
        // }, 10 * 1000); // NOTE: Testing

        return () => {
            clearInterval(intervalId);
        };
    });
}

const checkUnleashSession = handleUncaught(function* checkUnleashSession(action, retrying = false) {
    const { result, _timeout } = yield race({
        result: call(UnleashSource.get),
        _timeout: delay(Defaults.MAX_REQUEST_TIMEOUT),
    });

    if (!isNil(result)) {
        const { error, payload } = result;

        if (!error) {
            yield put(setUnleashSessionAction({ unleashSession: payload.sessionId }));
        } else {
            switch (payload.status) {
                case ErrorCodes.FETCH_ERROR: {
                    if (retrying !== true) {
                        // Wait for CONNECTION_RETRY_DELAY and try again
                        yield delay(Defaults.CONNECTION_RETRY_DELAY);
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.SERVICE_UNAVAILABLE: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
                case ErrorCodes.INTERNAL_SERVER_ERROR:
                default: {
                    if (retrying === true) {
                        yield call(logError, 'FetchUnleashSessionDataSaga', payload);
                    } else {
                        yield call(checkUnleashSession, action, true);
                    }
                    break;
                }
            }
        }
    } else {
        yield call(logError, 'FetchUnleashSessionDataSaga', INTERNAL_TIMEOUT_ERROR_PAYLOAD);
    }
});

export function* fetchAfterLoginData() {
    yield spawn(fetchAnnouncements);
    yield spawn(checkNewAppVersion);
    yield spawn(checkUnleashSession);
}

/**
 *
 * WATCHERS
 *
 */

function* watchNewAppVersionByInterval() {
    const channel = yield call(newAppVersionCheckIntervalChannel);
    yield takeEvery(channel, checkNewAppVersion);
}

function* watchMainOverviewDataRequests() {
    yield takeLatest(ActionTypes.DATA_MAIN_OVERVIEW_DATA_REQUESTED, fetchMainOverviewData);
}

// function* watchTrafficOverviewDataRequests() {
//     yield takeLatest(ActionTypes.DATA_TRAFFIC_OVERVIEW_DATA_REQUESTED, fetchTrafficOverviewData);
// }

function* watchBacklinkProfileOverviewDataRequests() {
    yield takeLatest(ActionTypes.DATA_BACKLINK_PROFILE_OVERVIEW_DATA_REQUESTED, fetchBacklinkProfileOverviewData);
}

function* watchCompetitorsOverviewDataRequests() {
    yield takeLatest(ActionTypes.DATA_COMPETITORS_OVERVIEW_DATA_REQUESTED, fetchCompetitorsOverviewData);
}

function* watchTopContentOverviewDataRequests() {
    yield takeLatest(ActionTypes.DATA_TOP_CONTENT_OVERVIEW_DATA_REQUESTED, fetchTopContentOverviewData);
}

// function* watchAudienceOverviewDataRequests() {
//     yield takeLatest(ActionTypes.DATA_AUDIENCE_OVERVIEW_DATA_REQUESTED, fetchAudienceOverviewData);
// }

function* watchHistoryRequests() {
    yield takeLatest(ActionTypes.DATA_HISTORY_REQUESTED, fetchHistoryData);
}

function* watchTrafficKeywordsExportRequests() {
    yield takeLatest(ActionTypes.DATA_TRAFFIC_KEYWORDS_EXPORT_REQUESTED, exportTrafficKeywords);
}

function* watchExportRequests() {
    yield takeLatest(ActionTypes.DATA_EXPORT_REQUESTED, exportData);
}

function* watchHistoryDeleteRequests() {
    yield takeLatest(ActionTypes.DATA_HISTORY_DELETE_REQUESTED, deleteHistoryData);
}

function* watchFetchAllDataAndPrint() {
    yield takeLatest(ActionTypes.DATA_FETCH_ALL_DATA_AND_PRINT, fetchAllDataAndPrint);
}

export function* watchDataRequests() {
    yield spawn(watchMainOverviewDataRequests);
    // yield spawn(watchTrafficOverviewDataRequests);
    yield spawn(watchBacklinkProfileOverviewDataRequests);
    yield spawn(watchTopContentOverviewDataRequests);
    // yield spawn(watchAudienceOverviewDataRequests);
    yield spawn(watchCompetitorsOverviewDataRequests);
    yield spawn(watchTrafficKeywordsExportRequests);
    yield spawn(watchExportRequests);
    yield spawn(watchHistoryRequests);
    yield spawn(watchHistoryDeleteRequests);
    yield spawn(watchNewAppVersionByInterval);
    yield spawn(watchFetchAllDataAndPrint);
}

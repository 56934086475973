import Urls from 'mangools-commons/dist/constants/Urls';

export const HELP_DROPDOWN_ITEMS = [
    {
        label: 'Guides on our blog',
        items: [
            {
                label: 'How to: SiteProfiler guide',
                link: `${Urls.SITEPROFILER_GUIDE_URL}?ref=header-app-sp`,
                description: '10 min read',
            },
            {
                label: 'SEO guide for beginners',
                link: `${Urls.MANGOOLS_SEO_ACADEMY_URL}?ref=header-app-sp`,
                description: '129 min read',
            },
        ],
    },
    {
        label: 'Other',
        items: [
            {
                label: 'FAQ',
                link: `${Urls.MANGOOLS_FAQ_URL}?ref=header-app-sp`,
            },
            {
                label: 'Mangools Blog',
                link: `${Urls.MANGOOLS_BLOG_URL}?ref=header-app-sp`,
            },
            {
                label: 'Affiliate program',
                link: `${Urls.MANGOOLS_AFFILIATE_URL}?ref=header-app-sp`,
            },
        ],
    },
];
